.lake-info-box {
    border-radius: var(--border-radius);
    margin: 0px;
    min-width: 300px;
    max-height: 400px;
    min-height: 320px;
    background-color: var(--primary-bg-color) !important;
    font-size: medium;
    font-family: var(--primary-font);
    color: var(--primary-text-color);
    font-weight: normal;
}

.lake-info-img {
    border-radius: var(--border-radius) !important;
    border: solid !important;
    border-width: 3px;
    border-color: var(--primary-border-color) !important;
    min-width: 300;
    max-height: 300;
    /* margin-top: 10px; */
}

.info-icon {
    height: 18px !important;
    width: 18px !important;
    margin-left: 6px;
}

.list-button {
    padding: 1px !important;
}

.collapsible {
    margin-left: 12px;
    font-size: 14px;
}

li {
    padding: 3px !important;
}

.gm-style .gm-style-iw-d::-webkit-scrollbar-track, 
.gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece,
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after,
.gm-style .gm-style-iw-tc::after { 
    background-color: var(--primary-bg-color) !important;
}


/* .gm-style .gm-style-iw-d::-webkit-scrollbar-track,  */
/* .gm-style .gm-style-iw-d::-webkit-scrollbar-track-piece, */
.gm-style .gm-style-iw-c,
.gm-style .gm-style-iw-t::after,
.gm-style .gm-style-iw-tc::after { 
    background-color: var(--primary-bg-color) !important;
    border-radius: 10px;
    border: solid;
    border-width: 3px;
    border-color: var(--primary-border-color);
}



