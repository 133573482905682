
.lake-filter-box {
    /* left: 5%; */
    width: 20%;
    max-width: 320px;
    min-width: 200px;
    display: inline-grid;
    /* flex-direction: column;     */
    /* display: grid; */
    /* box-sizing: border-box; */
    padding: 4px;
    margin: 4px;
    border-radius: var(--border-radius);
    border: solid;
    border-width: 3px;
    border-color: var(--primary-border-color);
    backdrop-filter: blur(4px);
    background-color: var(--primary-bg-color) !important;
    color: var(--primary-text-color) !important;
    /* float: "left"; */
    font-family: var(--primary-font);
    font-size: small;
}

.lake-filter-box > div {
    /* flex: 1; */
    /* display: inline-grid;
    column-gap: 50px; */
    margin: 4%;
  }

