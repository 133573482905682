
/* .places-container {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 300px;
  } */
  
.map-container {
  width: 100vw;
  height: 100vh;
  /* width: 100%;
  height: 100%; */
  position: 'relative';
  font-family: var(--primary-font);
}
