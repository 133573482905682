.legend {
    position: absolute;
    left: 4px;
    bottom: 4px;
    width: 20%;
    max-width: 320px;
    min-width: 260px;
    display: flex;
    /* display: grid; */
    /* grid-auto-columns: auto; */
    /* column-count: 3; */
    /* grid-column: auto; */
    /* flex-direction: row; */
    align-items: center;
    justify-content: center;
    column-gap: 6px;

    /* box-sizing: border-box; */
    /* padding-left: 1%;
    padding-right: 1%; */
    /* margin: 10px; */
    border-radius: var(--border-radius);
    border: solid;
    border-width: 3px;
    border-color: var(--primary-border-color);
    backdrop-filter: blur(4px);
    background-color: var(--primary-bg-color) !important;
    color: var(--primary-text-color) !important;
    /* float: "left"; */
    font-family: var(--primary-font);
    font-size: medium;
}

.legend > div {
    /* flex: 1; */
    /* column-width: auto; */
    margin: 2%;
    left: 0px;
    /* margin-left: 0px; */
    font-size: small;
    text-align: center;
}